@import url("https://use.typekit.net/hit0ibn.css");

.logo {
    height: 100px;
    pointer-events: none;
}

.link-read-more {
    color: #282529;
    text-decoration: none;
    cursor: pointer;

    :hover {
        color: #747474 !important;
    }
}


.link-mobile {
    text-align: center;
    font-family: utopia-std-display;
    color: #282529;
    text-decoration: none;
    // margin-left: 2%;
    font-size: 1.3rem;
    padding-bottom: 2%;
}

.my-link {
    cursor: pointer;

    &-home {
        display: inline-block;
    }

    &-side {
        text-decoration: none;

        :hover {
            color: #747474;
        }

        cursor: pointer;

    }

    &-view {
        text-decoration: none;
        color: black;

        :hover {
            color: #747474;
        }
    }
}

.nav-span {
    color: #747474;
}

.nav-item {

    &__left {
        color: black;
        display: block;
        margin: auto;
        margin-left: 0;
        margin-right: 3%;
        font-family: utopia-std-display;
        font-size: 1.5rem;
    }

    &__right {
        color: black;
        display: block;
        margin: auto;
        margin-left: 2.0%;
        margin-right: 0%;
        font-family: utopia-std-display;
        font-size: 1.5rem;
    }
}

.nav-background {
    background-color: white;
    padding-top: 1%;
    padding-bottom: 1%;
}

.navbar-brand {
    display: flex;
    align-items: center;
}

.sayhay-nav {
    margin-top: 3%;
}

.navbar-brand>img {
    padding: 7px 14px;
}

.tiles {
    padding: 2.5rem;

    @media (max-width: 570px) {
        padding: 0;
    }
}

.tile-carousel {
    padding-top: 2.5rem;

    @media (max-width: 570px) {
        padding-bottom: 2.5rem;
    }

    // padding-bottom: 2.5rem;
}

.col-sm-7 {
    &.col-tile-img {
        padding-left: 0px;
        padding-right: 0px;
    }
}


.tileImage {
    width: 100%;
    height: auto;
}

.tileImage-services {
    width: 100%;
    height: auto;

}

.tileImage-home {
    width: 100%;
    height: auto;

    filter: none;
    -webkit-filter: grayscale(0);

}

.tileImage-home:hover {
    filter: grayscale(100%);
    -webkit-filter: grayscale(100%);
}

.fade-in {
    opacity: 0;
}

.fade-in-appear {
    opacity: 1;
    transition: opacity 1000ms ease-in-out;

}

.work--image:hover {

    -webkit-filter: grayscale(100%);
    /* Ch 23+, Saf 6.0+, BB 10.0+ */
    filter: grayscale(100%);
    /* FF 35+ */
}

.tile-text {
    font-family: utopia-std-display;
    font-style: italic;

    @media (min-width: 1200px) {
        margin-bottom: 20%;
        margin-top: 35%;
        margin-left: 20%;
        margin-right: 20%;
        font-size: 1.8rem;
    }

    @media (min-width: 900px) and (max-width: 1199px) {
        margin-top: 15%;
        margin-left: 20%;
        margin-right: 15%;
        font-size: 1.5rem;
    }

    @media (min-width: 575) and (max-width: 899px) {
        margin-top: 15%;
        margin-left: 20%;
        margin-right: 15%;
        font-size: 1.0rem;
    }

    @media (max-width: 574px) {
        margin-top: 15%;
        margin-left: 20%;
        margin-right: 15%;
        font-size: 1.3rem;
    }

}

.tile-row {
    background-color: #f2f2f2;
}

.tile-footer {
    margin-left: 5%;

    @media (min-width: 920px) {
        padding-top: 30%;
    }

    @media (max-width: 919px) {
        padding-top: 20%;
    }

    &__brand {
        font-family: basic-sans;
        letter-spacing: 2px;

        @media (min-width: 920px) {
            padding-top: 20%;
            font-size: 1.3rem;
        }

        @media (max-width: 919px) {
            font-size: 1rem;
        }

    }

    &__category {
        font-family: utopia-std-display;

        @media (min-width: 920px) {
            font-size: 1.1rem;
        }

        @media (max-width: 919px) {
            font-size: 0.8rem;
            padding-bottom: 5%;
        }
    }

    &__view {
        float: right;
        letter-spacing: 2px;
        font-family: basic-sans;
        margin-right: 5%;

        // margin-right: 10%;
        @media (min-width: 920px) {
            padding-top: 3px;
            font-size: 1rem;
        }

        @media (max-width: 919px) {
            padding-top: 6px;
            font-size: 0.8rem;
        }
    }
}


.footer {
    margin: 2.5rem;
    font-family: basic-sans;

    @media (min-width: 920px) {
        font-size: 1.0rem;
        margin-bottom: 3%;
    }

    @media (max-width: 919px) {
        font-size: 0.8rem;
    }
}

.footer-mobile {
    margin-bottom: 2%;
}

.spacer {
    @media (min-width: 920px) {
        padding-top: 1rem;
    }

    @media (max-width: 919px) {
        padding-top: 1.3rem;
    }
}

.col {
    &.spacer-right {
        padding-left: 0px;
        margin-right: -10px;
        padding-right: 0px;
    }
}

.underline {
    text-decoration: underline;
}

.container {
    &.header {
        background-color: #f2f2f2;
    }
}

.work--image {
    max-width: 320px;
    margin: auto;
    display: block;
    width: auto;
    margin-top: 5%;

    // transition: filter .5s ease-in-out;
    -webkit-filter: grayscale(0%);
    /* Ch 23+, Saf 6.0+, BB 10.0+ */
    filter: grayscale(0%);
    /* FF 35+ */
}

.work-image__footer {
    margin-bottom: 5%;
}

.work__footer-text {
    font-family: basic-sans;
    letter-spacing: 2px;

    @media (min-width: 920px) {
        font-size: 1.2rem;
    }

    @media (min-width: 381px) and (max-width: 919px) {
        font-size: 1.0rem;
    }

    @media (max-width: 380px) {
        font-size: 0.8rem;
    }
}

.work__footer-subtext {
    font-family: utopia-std;

    @media (min-width: 920px) {
        font-size: 1.0rem;
    }

    @media (min-width: 381px) and (max-width: 919px) {
        font-size: 0.8rem;
    }

    @media (max-width: 380px) {
        font-size: 0.6rem;
    }
}

.work-filter__spacing {
    margin: 5%;
}

.work-filter__text {
    font-family: basic-sans;
    margin-bottom: 1%;

    @media (min-width: 920px) {
        letter-spacing: 2px;
        font-size: 1.0rem;
    }

    @media (min-width: 381px) and (max-width: 919px) {
        font-size: 0.75rem;
    }

    @media (max-width: 380px) {
        font-size: 0.6rem;
    }
}

.row {

    &.footer-last {
        @media (max-width: 919px) {
            padding-bottom: 5%;
        }

    }

    &.center {
        padding-top: 2rem;
        font-size: larger;
        text-align: center;

    }

    &.work--content {
        padding-top: 2rem;
    }

    &.services--category {
        justify-content: center;
        text-align: center;
        padding-left: 2.5rem;
        font-family: utopia-std-display;
        letter-spacing: 2px;
        margin-bottom: 5%;

        @media (min-width: 920px) {
            font-size: 1.2rem;
        }

        @media (min-width: 576px) and (max-width: 829px) {
            font-size: 0.8rem;
        }
    }

    &.head {
        justify-content: center;
        align-items: center;
        font-family: basic-sans;
        letter-spacing: 2px;

        @media (min-width: 920px) {
            margin-bottom: 5%;
            font-size: 1.5rem;
        }

        @media (max-width: 919px) {
            padding-top: 5%;
            font-size: 1.2rem;
            margin-bottom: -2%;
        }
    }
}


.services {
    padding-bottom: 2rem;
    margin-top: 5%;
    margin-right: 15%;
    margin-left: 10%;
    font-family: basic-sans;
    letter-spacing: 1.5px;



    @media (min-width: 921px) {

        font-size: 1.5rem;
    }

    @media (min-width: 700px) and (max-width: 920px) {
        padding-top: -10%;
        font-size: 1rem;
    }

    @media (min-width: 576px) and (max-width: 699px) {
        padding-top: 5%;
        font-size: 0.8rem;
    }

    &.text--sub-head {
        margin-top: 3%;
        margin-bottom: 5%;
        font-family: utopia-std-display;
        font-style: italic;

        @media (min-width: 700px) and (max-width: 920px) {
            font-size: 1em;
        }

        @media (min-width: 576px) and (max-width: 699px) {
            margin-top: -15%;
            margin-bottom: -15%;
            font-size: 0.7rem;
        }
    }

    &.text {
        font-family: basic-sans;
        letter-spacing: 1.5px;

        @media (min-width: 920px) {
            font-size: 1.2rem;
        }

        @media (min-width: 700px) and (max-width: 920px) {
            font-size: 0.8rem;
        }

        @media (min-width: 576px) and (max-width: 699px) {
            font-size: 0.7rem;
        }
    }
}

.work-sub {
    margin-right: 15%;
    margin-left: 10%;
    font-family: basic-sans;
    letter-spacing: 2px;

    @media(min-width: 1620px) {
        font-size: 1.3rem;
    }

    @media (min-width: 909px) and (max-width: 1300px) {
        font-size: 1.1rem;
    }

    @media (max-width: 908px) {
        padding-top: 5%;
        font-size: 1.2rem;
    }


    &.text--head {
        padding-bottom: 2rem;
        padding-top: 30%;
        font-size: 1.5rem;

        @media(min-width: 1201px) and (max-width: 1500px) {
            padding-top: 15%;
        }

        @media (min-width: 909px) and (max-width: 1200px) {
            font-size: 1.3rem;
            padding-top: 10%;
        }

        @media (min-width: 768px) and (max-width: 908px) {
            font-size: 1rem;
            padding-top: 5%;
            padding-bottom: 0;
        }

    }

    &.text {
        white-space: pre-line;
        padding-top: 5%;
        padding-bottom: 10%;
        letter-spacing: 1.5px;

        @media(min-width: 1301px) and (max-width: 1500px) {
            font-size: 1.0rem;
        }

        @media (min-width: 909px) and (max-width: 1300px) {
            padding-top: 0;
            padding-bottom: 3%;
            font-size: 0.8rem;
        }

        @media (min-width: 768px) and (max-width: 908px) {
            font-size: 0.7rem;
            padding-bottom: 0;
        }

    }

    &.text--footer {
        padding-top: 2%;
        letter-spacing: 1.5px;

        @media (min-width: 909px) and (max-width: 1300px) {
            font-size: 1.0rem;
        }

        @media (min-width: 768px) and (max-width: 908px) {
            font-size: 0.7rem;
            padding-bottom: 0;
        }


        &--category {
            font-family: utopia-std-display;
            padding-bottom: 5%;

            @media (min-width: 909px) and (max-width: 1300px) {
                font-size: 0.8rem;
                padding-top: 1%;
            }

            @media (min-width: 768px) and (max-width: 908px) {
                padding-top: 0;
                font-size: 0.7rem;
            }
        }
    }

    &.sub-page-link {
        margin-bottom: 2%;
        font-family: basic-sans;
        letter-spacing: 1.5px;


        @media(min-width: 1301px) and (max-width: 1600px) {
            font-size: 1.2rem;
        }

        @media (min-width: 909px) and (max-width: 1300px) {
            font-size: 0.8rem;
            padding-top: 1%;
        }

        @media (min-width: 768px) and (max-width: 908px) {
            font-size: 0.7rem;
        }

    }
}


.col-md-6 {
    &.work-sub-img {
        padding: 0px;
    }

    &.work-sub-text {
        padding: 0px;
    }
}

.col-sm-6 {
    &.home-carousel {
        width: 50%;
        padding-right: 0;
        padding-left: 0;
    }
}


.col-md-auto {
    &.work-sub-long-img {
        width: 100%;
        height: auto;
        padding: 0px;
    }
}